import { Table } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import CreateEmployee from "../../components/employee/createEmployee";
import PageHeader from "../../components/pageHeader";
import PageLoading from "../../components/pageLoading";
import { useGetAllEmployeesQuery } from "../../store/employee.store";

const Employees = () => {
  const { isLoading, isSuccess, data } = useGetAllEmployeesQuery();

  return (
    <div className="page_container">
      <PageHeader text="Employees" />
      {isLoading ? (
        <PageLoading />
      ) : (
        <>
          {isSuccess ? (
            <>
              <Table striped bordered hover responsive>
                <thead className="text-light">
                  <tr className="text-center">
                    <th>name</th>
                    <th>email</th>
                  </tr>
                </thead>
                <tbody>
                  {data.employees.map((employee) => (
                    <tr className="text-center" key={employee.id}>
                      <td data-title="course" className="fs-6">
                        {employee.name}
                      </td>
                      <td data-title="course" className="fs-6">
                        {employee.email}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {!data.employees.length && (
                <p className="alert text-center" style={{ fontWeight: "bold" }}>
                  No employees added
                </p>
              )}
              <div className="text-center">
                <CreateEmployee />
              </div>
            </>
          ) : (
            <Navigate to="505" />
          )}
        </>
      )}
    </div>
  );
};

export default Employees;
