import { Row } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import PageHeader from "../../components/pageHeader";
import PageLoading from "../../components/pageLoading";
import Statistic from "../../components/statistics/statistic";
import { useGetStatisticsQuery } from "../../store/statistics.store";

const Statistics = () => {
  const { isSuccess, isError, data } = useGetStatisticsQuery();

  return (
    <div className="page_container">
      <PageHeader text="Statistics" />
      {isSuccess ? (
        <Row>
          {[
            Object.entries(data.statistics).map(([statistic, count], index) => (
              <Statistic name={statistic} count={count} key={index} />
            )),
          ]}
        </Row>
      ) : isError ? (
        <Navigate to="/505" />
      ) : (
        <PageLoading />
      )}
    </div>
  );
};
export default Statistics;
