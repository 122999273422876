import { login, logout } from "../services/auth.service";
import { baseService } from "./base.store";

// Define a service using a base URL and expected endpoints
export const authService = baseService.injectEndpoints({
	endpoints: (builder) => ({
		login: builder.mutation<void, { email: string; password: string, role: string }>({
			queryFn: async ({ email, password, role }) =>
				await login({ email, password, role }),
		}),
		logout: builder.mutation<void, void>({
			queryFn: async () => await logout(),
			// async onQueryStarted(_, { dispatch, queryFulfilled }) {
			// 	try {
			// 		dispatch(
			// 			statisticsService.util.resetApiState()
			// 		)

			// 	} catch {
			// 		console.log('error')
			// 	}
			// }
		}),
	}),
});

export const { useLoginMutation, useLogoutMutation } = authService;
