import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { Admin } from "../entities/admin.entity";
import { Employee } from "../entities/employee.entity";
import { auth } from "../firebase";
import { adminRepository } from "../repositories/admin.repository";
import { employeeRepository } from "../repositories/employee.repository";
import {
	removeLoggedInKey,
	removeLoggedInRoleKey,
	setCookieWithKey,
	setLoggedInKey,
	setLoggedInRoleKey,
} from "../utils/browserStorage.util";

export const login = async ({
	email,
	password,
	role,
}: {
	email: string;
	password: string;
	role: string;
}): Promise<{ data: undefined } | { error: boolean }> => {
	try {
		await signInWithEmailAndPassword(auth, email, password);
		let admin: Admin | undefined | null;
		let employee: Employee | undefined | null;

		const roleLower = role.toLowerCase();

		if (roleLower === "admin") {
			admin = await adminRepository.findByEmail(email);
		}

		if (roleLower === "employee") {
			employee = await employeeRepository.findByEmail(email);
		}

		if (!admin && !employee) throw new Error();

		if (admin) setCookieWithKey("current-admin", admin);

		if (employee) setCookieWithKey("current-employee", employee);

		setLoggedInKey("true");
		setLoggedInRoleKey(roleLower);
		return { data: undefined };
	} catch (error) {
		return { error: true };
	}
};
export const logout = async (): Promise<
	{ data: undefined } | { error: boolean }
> => {
	try {
		await signOut(auth);
		removeLoggedInKey();
		removeLoggedInRoleKey();
		return { data: undefined };
	} catch (error) {
		return { error: true };
	}
};
