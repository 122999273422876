import { Table } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import CreateAdmin from '../../components/admin/createAdmin';
import PageHeader from '../../components/pageHeader';
import PageLoading from '../../components/pageLoading';
import { useGetAllAdminsQuery } from '../../store/admin.store';

const Admins = () => {
  const { isLoading, isSuccess, data } = useGetAllAdminsQuery();

  return (
    <div className='page_container'>
      <PageHeader text='Admins' />
      {isLoading ? (
        <PageLoading />
      ) : (
        <>
          {isSuccess ? (
            <>
              <Table striped bordered hover responsive>
                <thead className='text-light'>
                  <tr className='text-center'>
                    <th>name</th>
                    <th>email</th>
                  </tr>
                </thead>
                <tbody>
                  {data.admins.map((admin) => (
                    <tr className='text-center' key={admin.id}>
                      <td data-title='course' className='fs-6'>
                        {admin.firstName + " " + admin.lastName}
                      </td>
                      <td data-title='course' className='fs-6'>
                        {admin.email}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {!data.admins.length && <p className='alert'>no admins added</p>}
              <div className='text-center'>
                <CreateAdmin />
              </div>
            </>
          ) : (
            <Navigate to='505' />
          )}
        </>
      )}
    </div>
  );
};

export default Admins;
