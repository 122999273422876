import { Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getLoggedInRoleKey } from "../../utils/browserStorage.util";
import Logout from "./Logout";

const SideBar = () => {
  const role = getLoggedInRoleKey();
  const isAdmin = role === "admin";
  return (
    <Navbar className="flex-column align-items-stretch pl-2 bg-main-color">
      <Navbar.Brand className="pl-3 pt-4 mb-2">
        <Link to="/" className="h5 text-decoration-none text-white">
          Taaly
        </Link>
      </Navbar.Brand>
      <Nav className="nav nav-pills flex-column mb-4">
        <Nav.Link className="nav-link" as={Link} to="/">
          statistics
        </Nav.Link>
        {!isAdmin && (
          <>
            <Nav.Link className="nav-link" as={Link} to="/employees">
              employees
            </Nav.Link>
            <Nav.Link className="nav-link" as={Link} to="/students">
              students
            </Nav.Link>
            <Nav.Link className="nav-link" as={Link} to="/tutors">
              tutors
            </Nav.Link>
          </>
        )}
        {isAdmin && (
          <>
            <Nav.Link className="nav-link" as={Link} to="/admins">
              admins
            </Nav.Link>
            <Nav.Link className="nav-link" as={Link} to="/employees">
              employees
            </Nav.Link>
            <Nav.Link className="nav-link" as={Link} to="/organizations">
              organizations
            </Nav.Link>
            <Nav.Link className="nav-link" as={Link} to="/students">
              students
            </Nav.Link>
            <Nav.Link className="nav-link" as={Link} to="/tutors">
              tutors
            </Nav.Link>
            <Nav.Link className="nav-link" as={Link} to="/vouchers-codes">
              vouchers codes
            </Nav.Link>
            <Nav.Link
              className="nav-link"
              as={Link}
              to="/cancellation-requests"
            >
              cancellation requests
            </Nav.Link>
            <Nav.Link className="nav-link" as={Link} to="/withdrawal-requests">
              withdrawal requests
            </Nav.Link>
            <Nav.Link className="nav-link" as={Link} to="/bookings">
              bookings
            </Nav.Link>
            <Nav.Link className="nav-link" as={Link} to="/applying-tutors">
              applying tutors
            </Nav.Link>
            <Nav.Link className="nav-link" as={Link} to="/sessions">
              sessions
            </Nav.Link>
            <Nav.Link className="nav-link" as={Link} to="/chat-support">
              chat-support
            </Nav.Link>
            <Nav.Link className="nav-link" as={Link} to="/find-for-me-requests">
              find for me requests
            </Nav.Link>
            <Nav.Link className="nav-link" as={Link} to="/available-slots">
              available slots
            </Nav.Link>
            <Nav.Link className="nav-link" as={Link} to="/tutors-transactions">
              tutors transactions
            </Nav.Link>
            <Nav.Link
              className="nav-link mb-3"
              as={Link}
              to="/students-transactions"
            >
              students transactions
            </Nav.Link>
          </>
        )}
        <div className="tw-mt-auto">
          <Logout />
        </div>
      </Nav>
    </Navbar>
  );
};

export default SideBar;
