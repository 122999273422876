import { formatDateLabel } from '../../../utils/date.util';

export const ActiveChatDateLabel = ({ date }: { date: Date }) => {
  const dateLabel = formatDateLabel(date);
  return (
    <div className='tw-mx-auto  tw-w-max tw-py-2 tw-my-2 tw-px-4 tw-bg-white tw-rounded-md tw-text-sm  tw-text-gray-600 '>
      {dateLabel}
    </div>
  );
};
