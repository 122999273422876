import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import { useLoginMutation } from "../../store/auth.store";
import { getLoggedInKey } from "../../utils/browserStorage.util";
import { isDevlopment } from "../../utils/util";

type LoginData = {
  email: string;
  password: string;
  role: string; // Added role field
};

const Login = () => {
  const [login, loginResult] = useLoginMutation();
  const [redirect, setRedirect] = useState<boolean>();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginData>({
    defaultValues: {
      email: isDevlopment ? "test@taaly.com" : "",
      password: isDevlopment ? "123456" : "",
      role: "", // Default value for role
    },
  });

  const submitHandler: SubmitHandler<LoginData> = ({
    email,
    password,
    role,
  }) => {
    login({
      email,
      password,
      role, // Pass the role value
    });
  };

  useEffect(() => {
    let timeout: string | number | NodeJS.Timeout | undefined;
    if (loginResult.isSuccess) {
      timeout = setTimeout(() => {
        setRedirect(true);
      }, 2000);
    }
    return () => clearTimeout(timeout);
  }, [loginResult]);

  if (getLoggedInKey()) return <Navigate to="/" />;
  return (
    <div className="d-flex align-items-center" style={{ minHeight: "100vh" }}>
      <div className="w-100">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 offset-md-3">
              <p className="h2 text-main-color text-center">login</p>
              <form onSubmit={handleSubmit(submitHandler)}>
                <input
                  className={`form-control form-control-lg mb-3 ${
                    errors.email ? "border-danger" : ""
                  }`}
                  type="email"
                  placeholder="email"
                  aria-label="email"
                  {...register("email", { required: true })}
                />
                <input
                  className={`form-control form-control-lg mb-3 ${
                    errors.password ? "border-danger" : ""
                  }`}
                  type="password"
                  placeholder="password"
                  aria-label="password"
                  {...register("password", { required: true })}
                />
                <select
                  className={`form-control form-control-lg mb-3 ${
                    errors.role ? "border-danger" : ""
                  }`}
                  {...register("role", { required: true })}
                >
                  <option value="">Select Role</option>
                  <option value="admin">Admin</option>
                  <option value="employee">Employee</option>
                </select>
                {loginResult.error ? (
                  <div className="alert alert-danger mb-3" role="alert">
                    email or password is wrong
                  </div>
                ) : null}
                <div className="text-center">
                  <button type="submit" className="btn btn-primary btn-lg w-25">
                    login
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {redirect && <Navigate to="/"></Navigate>}
    </div>
  );
};

export default Login;
