import { Timestamp } from "firebase/firestore";
import { Student } from "./student.entity";
import { Tutor } from "./tutor.entity";

export type Session = {
	id: string;
	bookingId?: string | null;
	date: Timestamp;
	duration: number;
	endTime: Timestamp;
	sessionId: string;
	startTime: Timestamp;
	studentDeductedPoints: number;
	studentId: string;
	tutorAddedCredit: number;
	tutorId: string;
	type: string;
	student?: Student;
	tutor?: Tutor;
	state: SessionState;
};

export enum SessionState {
	RINGING = "ringing",
	REJECTED = "rejected",
	ENDED = "ended",
	MISSED = "missed",
	ACTIVE = "active",
}
