import {
	doc,
	getCountFromServer,
	getDoc,
	getDocs,
	orderBy,
	OrderByDirection,
	Query,
	where as whereQuery,
} from "firebase/firestore";
import { FilterQueryBuilder } from "../firebase/firestore/filterQueryBuilder";
import { Session, SessionState } from "../entities/session.entity";
import {
	SessionFilterQuery,
	SessionPaymentType,
	SessionType,
} from "../store/session.store";
import { Repository } from "../firebase/firestore/repository";

export class SessionRepository extends Repository<Session> {
	constructor() {
		super("sessions");
	}
	async findAll(
		where?: any,
		offset?: string,
		orderBy?: string | [string, OrderByDirection] | undefined,
		limit?: number
	): Promise<Session[]> {
		const query = await this.buildFilterQuery(where, offset, 10);
		const snapshot = await getDocs(query);
		return snapshot.docs.map((doc) => this.map(doc));
	}
	async count(where?: any): Promise<number> {
		const filterQuery = await this.buildFilterQuery(where || {});
		const countResult = await getCountFromServer(filterQuery);
		return countResult.data().count;
	}
	private async buildFilterQuery(
		where?: SessionFilterQuery,
		offset?: string,
		limit?: number
	): Promise<Query> {
		const queryBuilder = new FilterQueryBuilder(this.collection);
		if (where?.paymentType == SessionPaymentType.FREE) {
			queryBuilder.where(whereQuery("studentDeductedPoints", "==", 0));
		}
		if (where?.paymentType == SessionPaymentType.PAID) {
			queryBuilder.orderBy("studentDeductedPoints");
			queryBuilder.where(whereQuery("studentDeductedPoints", ">", 0));
		}
		if (where?.type == SessionType.BOOKED) {
			queryBuilder.where(whereQuery("bookingId", "!=", null));
		}
		if (where?.type == SessionType.DIRECT_CALL) {
			queryBuilder.where(whereQuery("bookingId", "==", null));
		}
		if (where?.state)
			queryBuilder.where(whereQuery("state", "==", where.state));
		queryBuilder.where(orderBy("date", "desc"));

		if (limit) queryBuilder.limit(limit);

		if (offset) {
			const document = await getDoc(doc(this.collection, offset));
			if (document.exists()) {
				queryBuilder.afterAt(document);
			}
		}
		return queryBuilder.build();
	}
}
export const sessionRepository = new SessionRepository();
