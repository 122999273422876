
import { DocumentData } from "@firebase/firestore-types";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { CreateEmployee, Employee } from "../entities/employee.entity";
import { auth } from "../firebase";
import { employeeRepository } from "../repositories/employee.repository";

export const createEmployee = async ({
	email,
	password,
	name,
}: CreateEmployee): Promise<{ data: Employee } | { error: boolean }> => {
	try {
		const emailIsUsed = await checkIsEmailIsUsed(email);
		if (emailIsUsed) return { error: true };

		await createUserWithEmailAndPassword(auth, email, password);
		const newEmployee = await employeeRepository.create({ name, email });
		return {
			data: {
				id: newEmployee,
				email,
				name,
			},
		};
	} catch (error) {
		return { error: true };
	}
};

export type FindEmployeesOptions = {
	offset: DocumentData;
	limit: number;
};

export const findEmployeesByQuery = async (
	query: FindEmployeesOptions | {}
): Promise<{ data: { employees: Employee[] } } | { error: boolean }> => {
	try {
		const employees = await employeeRepository.findAll();
		return { data: { employees } };
	} catch (error) {
		return { error: true };
	}
};

const checkIsEmailIsUsed = async (email: string): Promise<boolean> => {
	const employee = await employeeRepository.checkFieldIsUnique("email", email);
	return !employee;
};
