import {
    Project,
} from '../entities/project.entity';
import { ProjectsRepository } from '../repositories/project.repository';
import { GenericQueryReturnType } from '../store';



export const findProjectsForOrganization = async (orgId: string): Promise<
    GenericQueryReturnType<'projects', Project[]>
> => {
    try {
        const projectRepository = new ProjectsRepository(orgId);
        const projects = await projectRepository.findAll();
        return { data: { projects } };
    } catch (_) {
        return { error: true };
    }
};
