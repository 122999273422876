import { Col, Form, Row } from "react-bootstrap";
import { useState } from "react";
import { SessionFilterQuery, SessionPaymentType, SessionType } from "../../store/session.store";
import { SessionState } from "../../entities/session.entity";


const SessionsFilterForm = ({ setFilterQuery }: { setFilterQuery: (filter: SessionFilterQuery) => void }) => {
	const [filter, setFilter] = useState<SessionFilterQuery>({});

	const handleFilterChange = (key: string, value: SessionPaymentType | SessionState | SessionType) => {
		setFilterQuery({ ...filter, [key]: value });
		setFilter({ ...filter, [key]: value });
	}
	return (
		<Row>
			<Col sm={6}>
				<Form.Select
					onChange={(e) => handleFilterChange("type", parseInt(e.target.value) as SessionType)}
				>
					<option value={SessionType.ALL}>all</option>
					<option value={SessionType.BOOKED}>booked</option>
					<option value={SessionType.DIRECT_CALL}>direct call</option>
				</Form.Select>
			</Col>
			{/* <Col sm={6}>
				<Form.Select
					onChange={(e) => handleFilterChange("paymentType", parseInt(e.target.value) as SessionPaymentType)}
				>
					<option value={SessionPaymentType.ALL}>all</option>
					<option value={SessionPaymentType.FREE}>free</option>
					<option value={SessionPaymentType.PAID}>paid</option>
				</Form.Select>
			</Col> */}
			<Col sm={6}>
				<Form.Select
					onChange={(e) => e.target.value !== "all" ? handleFilterChange("state", e.target.value as SessionState) : null}
				>
					<option value="all">all</option>
					<option value={SessionState.ACTIVE}>{SessionState.ACTIVE}</option>
					<option value={SessionState.RINGING}>{SessionState.RINGING}</option>
					<option value={SessionState.ENDED}>{SessionState.ENDED}</option>
					<option value={SessionState.REJECTED}>{SessionState.REJECTED}</option>
					<option value={SessionState.MISSED}>{SessionState.MISSED}</option>
				</Form.Select>
			</Col>
		</Row>
	);
};

export default SessionsFilterForm;

// const handleStateChange = (state: SessionState) => {
// 	setFilterQuery({ ...filter, state });
// 	setFilter({ ...filter, state });
// };

// const handlePaymentTypeChange = (paymentType: SessionPaymentType) => {
// 	setFilterQuery({ ...filter, paymentType });
// 	setFilter({ ...filter, paymentType });
// };