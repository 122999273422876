import {  Alert, Button, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Session } from "../../entities/session.entity";
import { displayDate } from "../../utils/dateHandler.util";
import { SessionType } from "../../store/session.store";

const SessionsTable = ({ sessions } : { sessions: Session[] }) => {
    console.log(sessions)
    return (
        <>
            <Table striped bordered hover responsive>
                <thead className="text-light">
                    <tr className="text-center">
                        <th>start date</th>
                        <th>end date</th>
                        <th>state</th>
                        <th>points</th>
                        <th>credit</th>
                        <th>tutor</th>
                        <th>student</th>
                        <th>payment type</th>
                        <th>type</th>
                    </tr>
                </thead>
                <tbody>
                    {sessions.map((session, index) => (
                        <tr key={index} className="text-center">
                            <td>{displayDate(session.startTime)}</td>
                            <td>{session.endTime ? displayDate(session.endTime) : "..."}</td>
                            <td>{session.state}</td>
                            <td>{session.studentDeductedPoints || 0}</td>
                            <td>{session.tutorAddedCredit || 0}</td>
                            <td><Link to={`/tutors/${session.tutorId}`} className="text-decoration-none">{session.tutor?.name}</Link></td>
                            <td><Link to={`/students/${session.studentId}`} className="text-decoration-none">{session.student?.name}</Link></td>
                            <td>{session.tutor?.accountType}</td>
                            <td>{session.bookingId ? "BOOKED" : "DIRECT CALL"}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            { sessions.length === 0 ? <Alert variant="info">no sessions</Alert> : null}
        </>
    )
}

export default SessionsTable;