import { collection, getDocs } from "firebase/firestore";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useCollectionOnce } from "react-firebase-hooks/firestore";
import { useParams } from "react-router-dom";
import { Student } from "../../../entities/student.entity";
import { db } from "../../../firebase";
import { buildFilterQuery_v2 } from "../../../firebase/firestore/filterQueryBuilder";
import { fireStoreCollectionName } from "../../../utils/firebase.util";

import { Spinner } from "../../../components/shared/atoms/spinner";
import { OrganizationStudents } from "../../../components/students/organization-students/organization-students";
import { Session } from "../../../entities/session.entity";

export const OrganizationStudentsPage = () => {
  let { orgId } = useParams();
  const studentsQuery = useMemo(() => {
    const studentsRef = collection(db, fireStoreCollectionName.students);
    const studentsQuery = buildFilterQuery_v2<Student>(
      studentsRef,
      [["organizationId", "==", orgId]]
      // undefined,
      // undefined,
      // ['redeemedBy', 'asc']
    );
    return studentsQuery;
  }, [orgId]);
  const [studentsSnapshots] = useCollectionOnce(studentsQuery);
  const [isLoading, setIsLoading] = useState(true);
  const [studentData, setStudentsData] = useState<Student[]>([]);
  const handleData = useCallback(async () => {
    //  promises creditsVoucher by redeemedby
    if (!studentsSnapshots) return;

    const studentsPromises = studentsSnapshots.docs.map(
      async (studentSnapshot) => {
        const studentId = studentSnapshot.id;
        const sessionRef = collection(db, fireStoreCollectionName.sessions);
        const sessionsQuery = buildFilterQuery_v2<Session>(
          sessionRef,
          [
            ["studentId", "==", studentId],
            ["duration", ">", 0],
          ],
          undefined,
          undefined,
          ["duration", "desc"]
        );

        const sessionDocs = await getDocs(sessionsQuery);
        const numberOfSessions = sessionDocs.docs.length;
        console.log("numberOfSessions", numberOfSessions);
        const sessionSecs = sessionDocs.docs.reduce(
          (prev, next) =>
            prev + (isNaN(+next.data().duration) ? 0 : +next.data().duration),
          0
        );
        const sessionMins = +(sessionSecs / 60).toFixed(2);
        const student: Student = {
          ...studentSnapshot.data(),
          id: studentSnapshot.id,
          totalMinutes: sessionMins,
          noOfSessions: studentSnapshot.data().noOfSessions || 0,
        };
        return student;
      }
    );

    const students = await Promise.all(studentsPromises);
    //
    setStudentsData(students);
    setIsLoading(false);
  }, [studentsSnapshots]);
  useEffect(() => {
    if (studentsSnapshots) handleData();
  }, [studentsSnapshots, handleData]);
  if (!orgId) return null;
  if (isLoading)
    return (
      <div className="flex_center tw-h-full">
        <Spinner className=" tw-size-20" />
      </div>
    );
  return <OrganizationStudents allStudentsData={studentData} />;
};
