import { useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { Organization } from "../../../entities/organization.entity";
import { Student } from "../../../entities/student.entity";
import PageHeader from "../../pageHeader";
import {
  ReusableTable,
  TableHeader,
} from "../../shared/organisms/resuable-table";
import { OrganizationsStudentsTableHeader } from "./molecules/organization-students-table-header";

type Props = {
  allStudentsData: Student[];
};

export const OrganizationStudents = ({ allStudentsData }: Props) => {
  const { state } = useLocation() as { state: { organization: Organization } };
  const [serachFilter, setSearchFilter] = useState("");

  const tableHeaders: TableHeader<Student>[] = useMemo(
    () => [
      {
        key: "name",
        label: "name",
      },
      {
        key: "email",
        label: "email",
      },
      {
        key: "noOfSessions",
        label: "Sessions count",
      },
      {
        key: "totalMinutes",
        label: "Total minutes",
      },

      {
        key: "points",
        label: "Total points",
      },
      // minutes num is missing
    ],
    []
  );
  const navigate = useNavigate();

  const studentDataByFilter = useMemo(() => {
    if (!serachFilter) return allStudentsData;
    const regex = new RegExp(serachFilter, "i");

    return allStudentsData.filter(
      (student) => regex.test(student.email) || regex.test(student.name)
    );
  }, [serachFilter, allStudentsData]);

  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current!,
  });

  return (
    <div className=" tw-p-4">
      <PageHeader text={state.organization.name + " / students"} />
      <OrganizationsStudentsTableHeader
        handlePrint={() => {
          if (allStudentsData.length < 1) return;
          handlePrint();
        }}
        setSearchFilter={setSearchFilter}
      />

      <div className=" tw-mt-2" ref={componentRef}>
        <ReusableTable<Student>
          rows={studentDataByFilter}
          columns={tableHeaders}
          onLineCick={(student) => navigate("/students/" + student.id)}
        />
      </div>
    </div>
  );
};
