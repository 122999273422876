import { CreateEmployee, Employee } from "../entities/employee.entity";
import { createEmployee, findEmployeesByQuery } from "../services/employee.service";
import { baseService } from "./base.store";

export const employee = baseService.injectEndpoints({
    endpoints: (builder) => ({
        addNewEmployee: builder.mutation<Employee, CreateEmployee>({
            queryFn: async (newEmployee) => await createEmployee(newEmployee),
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                try {
                    const result = await queryFulfilled;
                    if (result.data)
                        dispatch(
                            employee.util.updateQueryData(
                                "getAllEmployees",
                                undefined,
                                (draftEmployees) => {
                                    draftEmployees.employees.push(result.data);
                                }
                            )
                        );
                } catch (_) { }
            },
        }),
        getAllEmployees: builder.query<{ employees: Employee[] }, void>({
            queryFn: async () => await findEmployeesByQuery({}),
        }),
    }),
});
export const { useGetAllEmployeesQuery, useAddNewEmployeeMutation } = employee;
