import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Student } from '../../entities/student.entity';
import DeleteStudent from './deleteStudent';
const StudentsTable = ({ students }: { students: Student[] }) => {
  return (
    <Table striped bordered hover responsive>
      <thead className='text-light'>
        <tr className='text-center'>
          <th>name</th>
          <th>email</th>
          <th>total points</th>
          <th>sessions</th>
          <th>organization</th>
          <th>show</th>
          <th>delete</th>
        </tr>
      </thead>
      <tbody>
        {students.map((student) => (
          <tr className='text-center' key={student.id}>
            <td data-title='name' className='fs-6'>
              {student.name}
            </td>
            <td data-title='email' className='fs-6'>
              {student.email}
            </td>
            <td data-title='points' className='fs-6'>
              {student.points + Math.abs(student.reservedPoints || 0)}
            </td>
            <td data-title='sessions' className='fs-6'>
              {student.noOfSessions || 0}
            </td>
            <td data-title='organization' className='fs-6'>
              {student.organizationId && student.organization?.name ? (
                <Link
                  to={'/organizations/students/' + student.organization?.id}
                  className='text-decoration-none'
                  state={{ organization: student.organization! }}
                >
                  {student.organization?.name}
                </Link>
              ) : (
                '...'
              )}
            </td>
            <td data-title='show' className='fs-6'>
              <Link
                to={'/students/' + student.id}
                className='text-decoration-none'
              >
                show
              </Link>
            </td>
            <td>
              <DeleteStudent student={student}/>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default StudentsTable;
