import { useState } from "react";
import { Alert } from "react-bootstrap";
import { Navigate, useSearchParams } from "react-router-dom";
import SearchForm from "../../components/SearchForm";
import PageHeader from "../../components/pageHeader";
import PageLoading from "../../components/pageLoading";
import Pagination from "../../components/pagination";
import SelectByOrganization from "../../components/selectByOrganization";
import StudentsTable from "../../components/students/studentsTable";
import usePagination from "../../customHooks/usePagination.hook";
import { Student } from "../../entities/student.entity";
import {
  useCountStudentsQuery,
  useGetStudentsQuery,
} from "../../store/student.store";

type StudentFilterQuery = {
  organizationId?: string;
  name?: string;
  email?: string;
};

const Students = () => {
  const [searchParams] = useSearchParams();
  const [filterQuery, setFilterQuery] = useState<StudentFilterQuery>({
    organizationId: searchParams.get("organization") || undefined,
  });
  const {
    data,
    numberOfPages,
    currentPage,
    next,
    prev,
    updateQuery,
    isLoading,
    isSuccess,
    isError,
  } = usePagination<Student>({
    useGetDataQuery: useGetStudentsQuery,
    useCountData: useCountStudentsQuery,
    initQuery: {
      organizationId: searchParams.get("organization") || undefined,
    },
  });
  const handleUpdateQuery = (newValues: any) => {
    updateQuery({
      ...filterQuery,
      ...newValues,
    });
    setFilterQuery({
      ...filterQuery,
      ...newValues,
    });
  };

  return (
    <div className="page_container">
      <PageHeader text="students" />
      <div className="mt-2">
        <SearchForm
          placeholder="Enter student name or email"
          handleSearch={(value) =>
            handleUpdateQuery({ name: value, email: value })
          }
        />
        <SelectByOrganization
          setOrganizationId={(organizationId) =>
            handleUpdateQuery({ organizationId })
          }
          defaultText="all students"
        />
      </div>
      {isSuccess ? (
        <>
          <StudentsTable students={data} />
          {data.length === 0 ? (
            <Alert variant="info">no matched students</Alert>
          ) : null}
          <Pagination
            numberOfPages={numberOfPages}
            currentPage={currentPage}
            prev={prev}
            next={next}
          />
        </>
      ) : isError ? (
        <Navigate to="505" />
      ) : (
        <PageLoading />
      )}
    </div>
  );
};

export default Students;
