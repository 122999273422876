import { Table } from 'react-bootstrap';
import { Link, Navigate } from 'react-router-dom';
import PageHeader from '../../components/pageHeader';
import PageLoading from '../../components/pageLoading';
import { Organization } from '../../entities/organization.entity';
import { useGetOrganizationsQuery } from '../../store/organization.store';

const Organizations = () => {
  const { isError, isSuccess, data } = useGetOrganizationsQuery(undefined, {
    refetchOnMountOrArgChange: true
  });

  return (
    <div className='page_container'>
      <PageHeader text='Organizations' />
      {isSuccess ? (
        <>
          <Table striped bordered hover responsive>
            <thead className='text-light'>
              <tr className='text-center'>
                <th>Name</th>
                <th>Email</th>
                <th>Active students</th>
                <th>Vouchers codes count</th>
                {/* <th>students</th> */}
                <th>-</th>
              </tr>
            </thead>
            <tbody>
              {data.organizations.map((organization: Organization) => (
                <tr className='text-center' key={organization.id}>
                  <td data-title='name' className='fs-6'>
                    {organization.name}
                  </td>
                  <td data-title='email' className='fs-6'>
                    {organization.email}
                  </td>
                  <td data-title='active-students' className='fs-6'>
                    {organization.activeStudentsCount || 0}
                  </td>
                  <td data-title='vouchers-codes-count' className='fs-6'>
                    {organization.vouchersCodesCount || 0}
                  </td>
                  <td>
                    <Link
                      className='btn btn-primary'
                      state={{ organization }}
                      to={`students/${organization.id}`}
                    >
                      show
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className='text-center'>
            {!data.organizations.length && (
              <p className='alert alert-info text-center'>no organizations</p>
            )}
            <Link className='btn btn-primary' to='/organizations/create'>
              add new organization
            </Link>
          </div>
        </>
      ) : isError ? (
        <Navigate to='/505' />
      ) : (
        <PageLoading />
      )}
    </div>
  );
};
export default Organizations;
