import { Col } from 'react-bootstrap';
import { Tutor } from '../../../entities/tutor.entity';

const TutorInfo = ({ tutor }: { tutor: Partial<Tutor> }) => {
  return (
    <div className='user-info'>
      <div className='info-row'>
        <label className='info-label'>About:</label>
        <span className='info-value'>{tutor.about}</span>
      </div>
      <div className='info-row'>
        <label className='info-label'>Name:</label>
        <span className='info-value'>{tutor.name}</span>
      </div>
      <div className='info-row'>
        <label className='info-label'>Email:</label>
        <span className='info-value'>{tutor.email}</span>
      </div>
      <div className='info-row'>
        <label className='info-label'>Total Credit:</label>
        <span className='info-value'>{tutor.totalCredit}</span>
      </div>
      <div className='info-row'>
        <label className='info-label'>Available Credit:</label>
        <span className='info-value'>{tutor.totalAvailableCredit}</span>
      </div>
      <div className='info-row'>
        <label className='info-label'>Country:</label>
        <span className='info-value'>{tutor.country}</span>
      </div>
      <div className='info-row'>
        <label className='info-label'>Account Type:</label>
        <span className='info-value'>{tutor.accountType}</span>
      </div>
      <div className='info-row'>
        <label className='info-label'>Sessions:</label>
        <span className='info-value'>{tutor.noOfSessions || 0}</span>
      </div>
      <div className='info-row'>
        <label className='info-label'>Gender:</label>
        <span className='info-value'>{tutor.gender}</span>
      </div>

      <div className='info-row'>
        <label className='info-label'>Native Language:</label>
        <span className='info-value'>{tutor.nativeLanguage}</span>
      </div>
    </div>
  );
};

export default TutorInfo;
