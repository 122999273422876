import { Navigate, Outlet } from 'react-router-dom';
import { getLoggedInKey, getLoggedInRoleKey } from '../utils/browserStorage.util';

type RoleGuardProps = {
  allowedRoles: string[];
};

const RoleGuard = ({ allowedRoles }: RoleGuardProps) => {
  const loggedIn = getLoggedInKey();
  const role = getLoggedInRoleKey();

  if (!loggedIn || !role) {
    return <Navigate to="/login" />;
  }

  if (!allowedRoles.includes(role)) {
    return <Navigate to="/unauthorized" />; // Optional: Redirect to an unauthorized page
  }

  return <Outlet />;
};

export default RoleGuard;
