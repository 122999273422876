import { FaFileImage } from 'react-icons/fa6';
import { MdRemoveCircle } from 'react-icons/md';

import { useEffect, useState } from 'react';
import { useChatSupport } from '../../../context/chat-support/chat-support.context';
import { useMenuToggle } from '../../../customHooks/useMenuToggle';
import { ModalWrapper } from '../../shared/molecules/modal-wrapper';
import { ActiveChatImage } from './active-chat-img';
import { Spinner } from '../../shared/atoms/spinner';
import { SendMSG_BTN } from './active-chat-send-msg-btn';
import { useChatSupportActiveMessages } from '../../../context/chat-support/chat-support-active-chat.context';
import { PendingMessage } from '../../../types/chat-support/chat-support.type';
import { auth } from '../../../firebase';
import { getCookieWithKey } from '../../../utils/browserStorage.util';
import { MessageType } from '../../../entities/chat-support.entity';

export const ImgUploadInput = () => {
  const [imgFile, setImgFile] = useState<File>();
  const [previewImg, setPreviewImg] = useState('');
  const { isOpen, openMenu, closeMenu } = useMenuToggle();
  const { actions } = useChatSupport();

  const {
    states: {
      pending: { setPendingMsgs }
    }
  } = useChatSupportActiveMessages();
  const handleSendImg = () => {
    // push message to peneding messages
    const pendingImgMsg: PendingMessage = {
      senderId: getCookieWithKey('current-admin').id!,
      file: imgFile,
      type: MessageType.IMAGE,
      content: previewImg,
      tempId: Math.random().toString()
    };
    setPendingMsgs((old) => [...old, pendingImgMsg]);
    handleClose();
  };
  const handleClose = () => {
    closeMenu();
    resetStates();
  };
  const resetStates = () => {
    setImgFile(undefined);
    setPreviewImg('');
  };
  return (
    <>
      <label
        htmlFor='img-input'
        className='  tw-size-[30px] circle-bg-hover  tw-p-2 '
      >
        <FaFileImage className='tw-text-lg' />
      </label>
      <input
        className='tw-hidden'
        type='file'
        onClick={(e) => {
          e.currentTarget.value = '';
        }}
        accept='image/*'
        onChange={(e) => {
          const imageFile = e.target.files ? e.target.files[0] : null;
          if (imageFile) {
            const isValid = actions.validateImg(imageFile);
            if (!isValid) return;
            setImgFile(imageFile!);
            openMenu();
            const reader = new FileReader();

            reader.onload = (e) => {
              setPreviewImg(e.target!.result as string);
            };

            reader.readAsDataURL(imageFile);
          }
        }}
        id='img-input'
      />

      <ModalWrapper
        headerBody=' Preview image before sending'
        footerBody={<SendMSG_BTN onClick={handleSendImg} />}
        isShow={isOpen}
        handleClose={handleClose}
      >
        <div className='tw-w-full tw-flex  tw-items-center '>
          {previewImg ? <ActiveChatImage src={previewImg} /> : <Spinner />}

          <div className='tw-size-[30px] tw-ms-auto tw-me-2 circle-bg-hover'>
            <MdRemoveCircle
              className='tw-text-red-500  tw-text-2xl '
              onClick={handleClose}
            />
          </div>
        </div>
      </ModalWrapper>
    </>
  );
};
