import { baseService } from "./base.store";
import { findSessions, countSessions } from "../services/session.service";
import { Session, SessionState } from "../entities/session.entity";

export enum SessionType {
	ALL = 0,
	BOOKED = 1,
	DIRECT_CALL = 2,
}

export enum SessionPaymentType {
	ALL = 0,
	FREE = 1,
	PAID = 2,
}
export type SessionFilterQuery = {
	paymentType?: SessionPaymentType;
	type?: SessionType;
	state?: SessionState;
};

export const sessionService = baseService.injectEndpoints({
	endpoints: (builder) => ({
		getSessions: builder.query<
			{ sessions: Session[] },
			{ where?: SessionFilterQuery; offset?: string }
		>({
			queryFn: async ({ where, offset }) =>
				await findSessions(where, offset),
		}),
		countSessions: builder.query<{ count: number }, { where: any }>({
			queryFn: async ({ where }) => await countSessions(where),
		}),
	}),
});

export const { useGetSessionsQuery, useCountSessionsQuery } = sessionService;
