import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useGetOrganizationsQuery } from "../../../../store/organization.store";
import { useFindProjectsForOrganizationQuery } from "../../../../store/project.store";
import { useBulkAddVouchersCodesMutation } from "../../../../store/voucherCode.store";
import { getDateAfterYear } from "../../../../utils/dateHandler.util";
import CopyTextToClipBoard from "../../../copyTextToCilpboard";

type CreateVoucherCodeParam = {
    noOfPoints: string;
    organizationId: string;
    projectId: string;
}

type Props = {
    vouchersCodes: string[],
    handleSuccess: () => void
}

const CreateOrganizationVoucherCodeForm = ({ vouchersCodes, handleSuccess }: Props) => {
    const organizations = useGetOrganizationsQuery();
    const [selectedOrganizationId, setSelectedOrganizationId] = useState<string | null>(null);
    const [selectedProjectId, setSelectedProjectId] = useState<string | null>();

    const projects =  useFindProjectsForOrganizationQuery(selectedOrganizationId || "");
    const [addOrganizationVouchersCode, addOrganizationVouchersCodeResult] = useBulkAddVouchersCodesMutation();
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm<CreateVoucherCodeParam>();

    useEffect(() => {
        if (addOrganizationVouchersCodeResult.isSuccess)
            handleSuccess();
    }, [addOrganizationVouchersCodeResult, handleSuccess]);

    const submitHandler: SubmitHandler<CreateVoucherCodeParam> = ({ organizationId, projectId, noOfPoints }) => {
        const expiryDate = getDateAfterYear();
        const vouchersCodesData = vouchersCodes.map(voucherCode => ({
            code: voucherCode,
            organizationId,
            projectId,
            noOfPoints: parseInt(noOfPoints),
            expiryDate,
            remainingPoints: parseInt(noOfPoints),
        }));
        addOrganizationVouchersCode(vouchersCodesData);
    }

    const handleOrganizationChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const organizationId = e.target.value;
        setSelectedOrganizationId(organizationId);
        setValue("organizationId", organizationId);
    }

   const handleProjectChange = (e: React.ChangeEvent<HTMLSelectElement>) => { 
        const projectId = e.target.value;
        setSelectedProjectId(projectId);
        setValue("projectId", projectId);
    }

    

    return (
        <form onSubmit={handleSubmit(submitHandler)}>
            <input className={`form-control form-control-lg mb-3 ${errors.noOfPoints ? 'border-danger' : ''}`}
                type="number"
                placeholder="Enter number of points"
                {...register('noOfPoints', { required: true })}
            />
            {
                organizations.isSuccess ?
                    <select
                        className={`form-control form-control-lg mb-3 ${errors.organizationId ? 'border-danger' : ''}`}
                        aria-label="select organization"
                        {...register("organizationId", { required: true })}
                        onChange={handleOrganizationChange}
                    >
                        <option value="">select organization</option>
                        {
                            organizations.data.organizations?.map(organization => (
                                <option
                                    value={organization.id}
                                    key={organization.id}
                                >{organization.name}</option>
                            ))
                        }
                    </select>
                    : null
            }
            {
                selectedOrganizationId && projects.isSuccess ?
                    <select
                        className={`form-control form-control-lg mb-3 ${errors.projectId ? 'border-danger' : ''}`}
                        aria-label="select project"
                        {...register("projectId", { required: true })}
                        onChange={handleProjectChange}
                    >
                        <option value="">select project</option>
                        {
                            projects.data.projects?.map(project => (
                                <option
                                    value={project.id}
                                    key={project.id}
                                >{project.name}</option>
                            ))
                        }
                    </select>
                    : null
            }
            <CopyTextToClipBoard text={vouchersCodes.join("\n")} />
            {
                addOrganizationVouchersCodeResult.error ? <div className="alert alert-danger mb-3" role="alert">something went wrong</div> : null
            }
            <div className="text-center">
                <button
                    type="submit"
                    className="btn btn-primary btn-lg w-25"
                    disabled={addOrganizationVouchersCodeResult.isLoading || !selectedOrganizationId || organizations.isLoading || projects.isLoading  || !selectedProjectId}
                >add</button>
            </div>
        </form>
    )
}

export default CreateOrganizationVoucherCodeForm;
