import { useLayoutEffect, useMemo, useState } from 'react';
import {
  ChatSupportContext,
  ChatSupportContextType
} from '../../context/chat-support/chat-support.context';
import {
  ChatContextFilter,
  ChatSummary
} from '../../types/chat-support/chat-support.type';
import { ChatSupportService } from '../../services/chat-support/chat-support.service';
import { ChatSupportRepository } from '../../repositories/chat-support.repository';
import { useRepoistoryFilter } from '../../customHooks/useRepoistoryFilter';
import { ChatNav } from '../../components/chat-support/organisms/chat-nav';
import { ActiveChat } from '../../components/chat-support/organisms/active-chat';

export const ChatSupportPage = () => {
  const [chats, setChats] = useState<ChatSummary[]>([]);
  const [activeChatRoom, setActiveChatRoom] = useState<ChatSummary>(
    null as unknown as ChatSummary
  );
  const [isfetching, setIsFetching] = useState(true);
  const { filter, updateFilter } = useRepoistoryFilter<ChatContextFilter>({
    limit: 40,
    orderBy: ['latestMessageDate', 'desc'],
    status: 'All'
  });
  const chatSupportService = useMemo(
    () => new ChatSupportService(new ChatSupportRepository()),
    []
  );

  const contextState: ChatSupportContextType['states'] = useMemo(
    () => ({
      chats: {
        chats,
        setChats,
        isfetching,
        setIsFetching
      },
      activeChatRoom: {
        activeChatRoom,
        setActiveChatRoom
      },
      filter: { filter, updateFilter }
    }),
    [chats, isfetching, activeChatRoom, filter, updateFilter]
  );

  useLayoutEffect(() => {
    chatSupportService.bindContextState({ ...contextState });
  }, [contextState, chatSupportService]);

  return (
    <ChatSupportContext.Provider
      value={{ states: contextState, actions: chatSupportService }}
    >
      <div className='tw-flex tw-h-[100vh] tw-bg-slate-100 tw-p-1 '>
        <div className=' tw-min-h-full tw-flex  tw-flex-col  tw-bg-white    tw-rounded-lg tw-drop-shadow-md tw-border  tw-w-[350px] '>
          <ChatNav />
        </div>
        <div className='tw-ms-4 tw-h-full  tw-w-full  tw-overflow-x-auto'>
          {activeChatRoom && <ActiveChat />}
        </div>
      </div>
    </ChatSupportContext.Provider>
  );
};
