import { Employee } from "../entities/employee.entity";
import { Repository } from "../firebase/firestore/repository";

export class EmployeeRepository extends Repository<Employee> {
    constructor() {
        super("employees");
    }
    async findByEmail(email: string): Promise<Employee | null> {
        const employeeQueryResult = await this.findAll({ email });
        return employeeQueryResult.length === 1 ? employeeQueryResult[0] : null;
    }
}

export const employeeRepository = new EmployeeRepository();
