import { FC, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import CreateAdminForm from "./CreateEmployeeForm";

interface CreateAdminModelProps {
  onHide: () => void;
  [key: string]: any;
}

const CreateAdminModel: FC<CreateAdminModelProps> = ({ onHide, ...props }) => {
  return (
    <Modal
      {...props}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add new Employee
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CreateAdminForm setHide={onHide} />
      </Modal.Body>
    </Modal>
  );
};

const CreateAdmin = () => {
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      <Button variant="primary" onClick={() => setModalShow(true)}>
        Add new employee
      </Button>

      <CreateAdminModel show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};
export default CreateAdmin;
