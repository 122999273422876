import { Student } from '../entities/student.entity';
import { Tutor, TutorAccountType } from '../entities/tutor.entity';
import {
  addTutorsToPrivateStudent,
  convertStudentToTutor,
  countStudents,
  deleteAllStudentData,
  deleteStudentById,
  findPrivateStudentTutors,
  findStudentById,
  findStudents,
  removeTutorFromPrivateStudent,
  updateStudentById
} from '../services/student.service';
import { baseService } from './base.store';

// Define a service using a base URL and expected endpoints
export const studentService = baseService.injectEndpoints({
  endpoints: (builder) => ({
    getStudents: builder.query<
      { students: Student[] },
      { offset?: string; where?: any }
    >({
      queryFn: async ({ where, offset }) => await findStudents(where, offset),
      providesTags: ['Student']
    }),
    getStudentById: builder.query<{ student: Student }, string>({
      queryFn: async (id) => await findStudentById(id)
    }),
    updateStudentById: builder.mutation<
      void,
      { id: string; newData: Partial<Student> }
    >({
      queryFn: async ({ id, newData }) => await updateStudentById(id, newData)
    }),
    deleteStudentById: builder.mutation<void, string>({
      queryFn: async (id) => await deleteStudentById(id),
      invalidatesTags: ['Student']
    }),
    deleteAllStudentData: builder.mutation<void, string>({
      queryFn: async (studentId) => await deleteAllStudentData(studentId),
      invalidatesTags: ['Student']
    }),
    countStudents: builder.query<{ count: number }, { where?: any }>({
      queryFn: async ({ where }) => await countStudents(where),
      providesTags: ['Student']
    }),
    convertStudentToTutor: builder.mutation<
      void,
      { id: string; accountType: TutorAccountType }
    >({
      queryFn: async ({ id, accountType }) =>
        await convertStudentToTutor(id, accountType),
      invalidatesTags: ['Tutor', 'Student']
    }),
    addTutorsToPrivateStudent: builder.mutation<
      void,
      { id: string; tutors: Partial<Tutor>[] }
    >({
      queryFn: async ({ id, tutors }) =>
        await addTutorsToPrivateStudent(id, tutors),
      invalidatesTags: ['PrivateStudent']
    }),
    removeTutorFromPrivateStudent: builder.mutation<
      void,
      { id: string; tutorId: string }
    >({
      queryFn: async ({ id, tutorId }) =>
        await removeTutorFromPrivateStudent(id, tutorId),
      invalidatesTags: ['PrivateStudent']
    }),
    getPrivateStudentTutors: builder.query<{ tutors: Tutor[] }, { id: string }>(
      {
        queryFn: async ({ id }) => await findPrivateStudentTutors(id),
        providesTags: ['PrivateStudent']
      }
    )
  })
});

export const {
  useCountStudentsQuery,
  useGetStudentsQuery,
  useDeleteStudentByIdMutation,
  useDeleteAllStudentDataMutation,
  useGetStudentByIdQuery,
  useUpdateStudentByIdMutation,
  useConvertStudentToTutorMutation,
  useGetPrivateStudentTutorsQuery,
  useAddTutorsToPrivateStudentMutation,
  useRemoveTutorFromPrivateStudentMutation
} = studentService;
