import { SubmitHandler, useForm } from 'react-hook-form';
import { ImgUploadInput } from '../atoms/img-upload-input';
import { SendMSG_BTN } from '../atoms/active-chat-send-msg-btn';
import { PendingMessage } from '../../../types/chat-support/chat-support.type';
import { getCookieWithKey } from '../../../utils/browserStorage.util';
import { MessageType } from '../../../entities/chat-support.entity';
import { useChatSupportActiveMessages } from '../../../context/chat-support/chat-support-active-chat.context';
import { useRef } from 'react';

type Inputs = {
  newMessage: string;
};

export const ActiveChatInput = () => {
  const { register, handleSubmit, reset } = useForm<Inputs>();
  const formRef = useRef<HTMLFormElement>(null);
  const {
    states: {
      pending: { setPendingMsgs }
    }
  } = useChatSupportActiveMessages();
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const newPendingMsg: PendingMessage = {
      content: data.newMessage,
      senderId: getCookieWithKey('current-admin').id!,
      type: MessageType.TEXT,
      tempId: Math.random().toString()
    };
    setPendingMsgs((old) => {
      return [...old, newPendingMsg];
    });
    reset();
  };
  return (
    <div className='tw-flex tw-items-center tw-p-2 tw-justify-between  tw-w-full'>
      <ImgUploadInput />

      <form
        ref={formRef}
        className='tw-flex-1 tw-flex tw-items-center tw-gap-2'
        onSubmit={handleSubmit(onSubmit)}
      >
        <textarea
          {...register('newMessage', { required: true })}
          className='custom-input  tw-overflow-y-auto tw-bg-white tw-min-h-[50px] tw-h-[50px] tw-max-h-[150px] tw-resize-none'
          onInput={(e) => {
            const chatInput = e.currentTarget;
            chatInput.style.height = '0px'; // element has css min-height
            const scrollHeight = chatInput.scrollHeight;
            chatInput.style.height = scrollHeight + 'px'; // element has css max-height
          }}
        />

        <SendMSG_BTN />
      </form>
    </div>
  );
};
