import {
  and,
  collection,
  deleteDoc,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  or,
  OrderByDirection,
  Query,
  where,
  writeBatch
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { Student } from '../entities/student.entity';
import { Tutor } from '../entities/tutor.entity';
import { db } from '../firebase';
import { FilterQueryBuilder } from '../firebase/firestore/filterQueryBuilder';
import { Repository } from '../firebase/firestore/repository';
import { functions } from '../firebase/index';

export class TutorRepository extends Repository<Tutor> {
  constructor() {
    super('tutors');
  }

  async findAll(
    filter?: any,
    offset?: string,
    orderBy?: string | [string, OrderByDirection] | undefined,
    limit?: number
  ): Promise<Tutor[]> {
    const snapshot = await getDocs(
      await this.buildFilterQuery(filter, offset, orderBy, limit || 10)
    );
    return snapshot.docs.map((doc) => this.map(doc));
  }
  async count(where?: any): Promise<number> {
    const filterQuery = await this.buildFilterQuery(where || {});
    const countResult = await getCountFromServer(filterQuery);
    return countResult.data().count;
  }

  async countExistingTutors(isAccepted: boolean = true): Promise<number> {
    const filterQuery = new FilterQueryBuilder(this.collection)
      .where(where('userId', '!=', null))
      .where(where('isAccepted', '==', isAccepted))
      .build();
    const countResult = await getCountFromServer(filterQuery);
    return countResult.data().count;
  }

  private async buildFilterQuery(
    filter: any,
    offset?: string,
    orderBy?: string | [string, OrderByDirection],
    limit?: number
  ): Promise<Query> {
    const query = new FilterQueryBuilder(this.collection);

    if (filter && Object.keys(filter).length > 0) {
      if (filter.name && filter.email)
        query.and(
          or(
            and(
              where('name', '>=', filter.name),
              where('name', '<=', filter.name + '\uf8ff')
            ),
            where('email', '==', filter.email)
          )
        );
      else query.whereFieldEquals('isAccepted', filter.isAccepted);
    }

    if (orderBy) query.orderBy(orderBy);
    if (limit) query.limit(limit);
    if (offset) {
      const document = await getDoc(doc(this.collection, offset));
      if (document.exists()) query.afterAt(document);
    }
    return query.build();
  }

  async findPrivateTutorStudents(id: string): Promise<Student[]> {
    const students = await getDocs(
      collection(db, this.collectionName, id, 'students')
    );
    return students.docs.map((student) => this.mapTo<Student>(student));
  }

  async addStudentsToPrivateTutor(
    tutorId: string,
    students: Partial<Student>[]
  ): Promise<void> {
    const tutorStudentsSubCollection = collection(
      db,
      this.collectionName,
      tutorId,
      'students'
    );

    const batch = writeBatch(db);
    students.forEach((student) => {
      batch.set(doc(tutorStudentsSubCollection, student.id), student);
    });
    await batch.commit();
  }
  async deleteStudentFromPrivateTutor(
    tutorId: string,
    studentId: string
  ): Promise<void> {
    const tutorStudentsSubCollection = collection(
      db,
      this.collectionName,
      tutorId,
      'students'
    );
    const student = await getDoc(doc(tutorStudentsSubCollection, studentId));
    await deleteDoc(student.ref);
  }
}

export const tutorRepository = new TutorRepository();
