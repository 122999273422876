import { useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useGenerateRandomVouchersCodesByCountQuery } from "../../../../store/voucherCode.store";
import CreateOrganizationVoucherCodeForm from "./createOrganizationVoucherCodeForm";

type Props = {
  setHide: () => void;
};
const CreateOrganizationVouchersCodes = ({ setHide }: Props) => {
  const [generateRandomVouchersCodes, setGenerateRandomVouchersCodes] =
    useState<boolean>();
  const [numberOfVouchersCodes, setNumberOfVouchersCodes] = useState<number>();

  const randomVouchersCodes = useGenerateRandomVouchersCodesByCountQuery(
    numberOfVouchersCodes || 1,
    { skip: !generateRandomVouchersCodes, refetchOnMountOrArgChange: true }
  );

  return generateRandomVouchersCodes && randomVouchersCodes.isSuccess ? (
    <CreateOrganizationVoucherCodeForm
      handleSuccess={setHide}
      vouchersCodes={randomVouchersCodes.data.codes}
    />
  ) : (
    <InputGroup className="mb-3">
      <Form.Control
        placeholder="Enter number of vouchers codes"
        type="number"
        onChange={(e) => setNumberOfVouchersCodes(parseInt(e.target.value))}
      />
      <Button
        variant="outline-secondary"
        onClick={() => setGenerateRandomVouchersCodes(true)}
      >
        Generate
      </Button>
    </InputGroup>
  );
};

export default CreateOrganizationVouchersCodes;
