import { DocumentData } from '@firebase/firestore-types';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc, Timestamp } from 'firebase/firestore';
import { Admin, CreateAdmin } from '../entities/admin.entity';
import { auth, db } from '../firebase';
import { adminRepository } from '../repositories/admin.repository';
import { UserDbModel } from '../types/user.types';

export const createAdmin = async ({
  email,
  password,
  firstName,
  lastName
}: CreateAdmin): Promise<{ data: Admin } | { error: boolean }> => {
  try {
    const emailIsUsed = await checkIsEmailIsUsed(email);
    if (emailIsUsed) return { error: true };

    const user = await createUserWithEmailAndPassword(auth, email, password);

    const admin: Admin = {
      firstName,
      lastName,
      email,
      role: 'ADMIN',
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now()
    };

    const adminId = user.user.uid;

    const data: UserDbModel = {
      ...admin,
      preferences: {
        locale: 'en',
        receiveNotification: true
      },
      organizationsIds: [],
      phone: '',
      lastLogin: Timestamp.now(),
      projectsIds: []
    };

    await setDoc(doc(db, 'User', user.user.uid), data);

    return {
      data: {
        id: adminId,
        ...admin
      }
    };
  } catch (error) {
    return { error: true };
  }
};

export type FindAdminsOptions = {
  offset: DocumentData;
  limit: number;
};

export const findAdminsByQuery = async (
  query: FindAdminsOptions | {}
): Promise<{ data: { admins: Admin[] } } | { error: boolean }> => {
  try {
    const admins = await adminRepository.findAll({ role: 'ADMIN' });
    return { data: { admins } };
  } catch (error) {
    return { error: true };
  }
};

const checkIsEmailIsUsed = async (email: string): Promise<boolean> => {
  const admin = await adminRepository.checkFieldIsUnique('email', email);
  return !admin;
};
