import { Timestamp } from 'firebase/firestore';

export const ChatSupportCollectionName = 'chatSupport';

export type ChatSupportDoc = Omit<ChatSupport, 'messages'>;
export interface ChatSupport {
  status: ChatStatus;
  createdAt: Timestamp;
  tutorId?: string;
  unreadCount: {
    user: number;
    support: number;
  };
  latestMessageDate: Timestamp;
  messages: ChatMessage[]; // sub_collection of messages
}

export interface ChatMessage {
  id: string;
  content: string;
  type: MessageType;
  senderId: string;
  createdAt: Timestamp;
}

export type ChatMessageDoc = Omit<ChatMessage, 'id'>;

export enum ChatStatus {
  OPEN = 0,
  CLOSED = 1
}
export enum MessageType {
  TEXT = 0,
  IMAGE = 1
}
