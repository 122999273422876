import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import PageHeader from '../../components/pageHeader';
import PageLoading from '../../components/pageLoading';
import Pagination from '../../components/pagination';
import SearchForm from '../../components/SearchForm';
import TutorsTable from '../../components/tutors/tutorsTable';
import usePagination from '../../customHooks/usePagination.hook';
import { Tutor } from '../../entities/tutor.entity';
import {
  useCountTutorsQuery,
  useGetTutorsQuery
} from '../../store/tutor.store';

type TutorFilterQuery = {
  isAccepted?: boolean;
  tutorId?: string;
  name?: string;
  email?: string;
};

const Tutors = () => {
  const [filterQuery, setFilterQuery] = useState<TutorFilterQuery>({});

  const {
    data,
    numberOfPages,
    currentPage,
    next,
    prev,
    updateQuery,
    isLoading,
    isSuccess,
    isError
  } = usePagination<Tutor>({
    useGetDataQuery: useGetTutorsQuery,
    useCountData: useCountTutorsQuery
  });

  const handleUpdateQuery = (newValues: any) => {
    updateQuery({
      ...newValues
    });
    setFilterQuery({
      ...newValues
    });
  };
  return (
    <div className='page_container'>
      <PageHeader text='tutors' />
      <div className='mt-2'>
        <SearchForm
          placeholder='Enter tutor name or email'
          handleSearch={(value) => {
            if (!value) {
              handleUpdateQuery({});
            } else {
              handleUpdateQuery({ name: value, email: value });
            }
          }}
        />
      </div>
      {isSuccess ? (
        <>
          <TutorsTable tutors={data} />
          <Pagination
            numberOfPages={numberOfPages}
            currentPage={currentPage}
            prev={prev}
            next={next}
          />
        </>
      ) : isError ? (
        <Navigate to='505' />
      ) : (
        <PageLoading />
      )}
    </div>
  );
};

export default Tutors;
