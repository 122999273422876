import {
    Project
} from "../entities/project.entity";
import {
    findProjectsForOrganization,
} from "../services/projects.service";
import { baseService } from "./base.store";
// Define a service using a base URL and expected endpoints
export const projectsServices = baseService.injectEndpoints({
    endpoints: (builder) => ({
        findProjectsForOrganization: builder.query<
            { projects: Project[] },
            string
        >({
            queryFn: async (orgId) => await findProjectsForOrganization(orgId),
        }),

    }),
});
export const {
    useFindProjectsForOrganizationQuery,
} = projectsServices;
