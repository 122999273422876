import { Route, Routes } from "react-router-dom";
import Layout from "../components/layout";
import Admins from "../pages/admins";
import ApplyingTutors from "../pages/applyingTutors";
import ShowApplyingTutor from "../pages/applyingTutors/id/show";
import AvailableSlots from "../pages/availableSlots";
import Bookings from "../pages/bookings";
import CancellationRequests from "../pages/cancellationRequests";
import CancellationRequestPage from "../pages/cancellationRequests/id/show";
import { ChatSupportPage } from "../pages/chat-support/chat-support";
import Employees from "../pages/employees";
import FindForMeNowRequests from "../pages/findForMeNowRequests";
import Login from "../pages/login";
import Organizations from "../pages/organizations";
import CreateOrganization from "../pages/organizations/create";
import Sessions from "../pages/sessions";
import Statistics from "../pages/statistics";
import Students from "../pages/students";
import PrivateStudentTutors from "../pages/students/id/private";
import ShowStudent from "../pages/students/id/show";
import { OrganizationStudentsPage } from "../pages/students/organization/organization-students";
import StudentsTransactionHistory from "../pages/studentsTransactionsHistory";
import Tutors from "../pages/tutors";
import ShowTutor from "../pages/tutors/id/show";
import TutorsTransactionHistory from "../pages/tutorsTransactionsHistory";
import TutorWithdrawalRequests from "../pages/tutorsWithdrawalRequests";
import Invoice from "../pages/tutorsWithdrawalRequests/id/invoice";
import VouchersCodes from "../pages/voucherCode";
import ShowVoucherCode from "../pages/voucherCode/show";
import Guard from "./guard.route";

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route element={<Guard allowedRoles={["admin", "employee"]} />}>
        <Route path="/*" element={<Layout />}>
          <Route path="employees/*">
            <Route index element={<Employees />} />
          </Route>
          <Route path="tutors/*">
            <Route index element={<Tutors />} />
            <Route path=":id/*">
              <Route index element={<ShowTutor />} />
            </Route>
          </Route>
          <Route path="students/*">
            <Route index element={<Students />} />
            <Route path=":id/*">
              <Route index element={<ShowStudent />} />
              <Route path="private" element={<PrivateStudentTutors />} />
            </Route>
          </Route>
          <Route index element={<Statistics />} />
        </Route>
      </Route>

      <Route element={<Guard allowedRoles={["admin"]} />}>
        <Route path="/*" element={<Layout />}>
          <Route path="organizations/">
            <Route index element={<Organizations />} />
            <Route
              path="students/:orgId"
              element={<OrganizationStudentsPage />}
            />
            <Route path="create" element={<CreateOrganization />} />
          </Route>
          <Route path="admins/*">
            <Route index element={<Admins />} />
          </Route>
          <Route path="vouchers-codes">
            <Route index element={<VouchersCodes />} />
            <Route path=":id" element={<ShowVoucherCode />} />
          </Route>
          <Route path="students/*">
            <Route index element={<Students />} />
            <Route path=":id/*">
              <Route index element={<ShowStudent />} />
              <Route path="private" element={<PrivateStudentTutors />} />
            </Route>
          </Route>
          <Route path="applying-tutors/*">
            <Route index element={<ApplyingTutors />} />
            <Route path=":id/*">
              <Route index element={<ShowApplyingTutor />} />
            </Route>
          </Route>
          <Route path="withdrawal-requests/*">
            <Route index element={<TutorWithdrawalRequests />} />
            <Route path=":id/*">
              <Route path="invoice" element={<Invoice />}></Route>
            </Route>
          </Route>
          <Route path="bookings/*">
            <Route index element={<Bookings />} />
          </Route>
          <Route path="cancellation-requests/*">
            <Route index element={<CancellationRequests />} />
            <Route path=":id/*">
              <Route index element={<CancellationRequestPage />}></Route>
            </Route>
          </Route>
          <Route path="sessions/*">
            <Route index element={<Sessions />} />
          </Route>
          <Route path="chat-support/*">
            <Route index element={<ChatSupportPage />} />
          </Route>
          <Route path="find-for-me-requests/">
            <Route index element={<FindForMeNowRequests />} />
          </Route>
          <Route path="tutors-transactions">
            <Route index element={<TutorsTransactionHistory />} />
          </Route>
          <Route path="students-transactions">
            <Route index element={<StudentsTransactionHistory />} />
          </Route>
          <Route path="available-slots">
            <Route index element={<AvailableSlots />} />
          </Route>
          <Route path="404" element={<>404</>} />
          <Route path="505" element={<>505</>} />
        </Route>
      </Route>
    </Routes>
  );
}
