import {
  and,
  collection,
  deleteDoc,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  or,
  OrderByDirection,
  Query,
  where,
  writeBatch
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { Student } from '../entities/student.entity';
import { Tutor } from '../entities/tutor.entity';
import { db } from '../firebase';
import { FilterQueryBuilder } from '../firebase/firestore/filterQueryBuilder';
import { Repository } from '../firebase/firestore/repository';
import { functions } from '../firebase/index';
import { FireDoc } from '../types/util.types';

export class StudentRepository extends Repository<Student> {
  constructor() {
    super('students');
  }
  async findAll(
    filter?: any,
    offset?: string,
    orderBy?: string | [string, OrderByDirection] | undefined,
    limit?: number
  ): Promise<Student[]> {
    const query = await this.buildStudentsFilterQuery(
      filter,
      offset,
      orderBy,
      limit || 10
    );
    const snapshot = await getDocs(query);
    return snapshot.docs.map((doc) => this.map(doc));
  }
  async count(where?: any): Promise<number> {
    const filterQuery = await this.buildStudentsFilterQuery(where || {});
    const countResult = await getCountFromServer(filterQuery);
    return countResult.data().count;
  }

  async countExistingStudents(): Promise<number> {
    const filterQuery = new FilterQueryBuilder(this.collection)
      .where(where('userId', '!=', null))
      .build();
    const countResult = await getCountFromServer(filterQuery);
    return countResult.data().count;
  }

  async studentsByOrganizationId(
    orgId: string
  ): Promise<FireDoc<Student>['DocWithIdAndRef'][]> {
    const studentSnapshots = await this.findByFilter({
      where: [['organizationId', '==', orgId]]
    });
    const studentDocs = studentSnapshots.docs.map((docSnap) => ({
      ...docSnap.data(),
      id: docSnap.id,
      docRef: docSnap.ref
    }));
    return studentDocs;
  }
  private async buildStudentsFilterQuery(
    filter: any,
    offset?: string,
    orderBy?: string | [string, OrderByDirection],
    limit?: number
  ): Promise<Query> {
    try {
      const query = new FilterQueryBuilder(this.collection);

      const filterQuery = [];
      if (filter.name && filter.email)
        filterQuery.push(
          or(
            and(
              where('name', '>=', filter.name),
              where('name', '<=', filter.name + '\uf8ff')
            ),
            where('email', '==', filter.email)
          )
        );
      if (filter.organizationId)
        filterQuery.push(where('organizationId', '==', filter.organizationId));
      if (filterQuery.length) query.and(...filterQuery);
      if (orderBy) query.orderBy(orderBy);
      if (limit) query.limit(limit);

      if (offset) {
        const document = await getDoc(doc(this.collection, offset));
        if (document.exists()) query.afterAt(document);
      }

      return query.build();
    } catch (er) {
      console.log(er);
      throw new Error();
    }
  }

  async findPrivateStudentTutors(id: string): Promise<Tutor[]> {
    const tutors = await getDocs(
      collection(db, this.collectionName, id, 'tutors')
    );
    return tutors.docs.map((tutor) => this.mapTo<Tutor>(tutor));
  }

  async addTutorsToPrivateStudent(
    studentId: string,
    tutors: Partial<Tutor>[]
  ): Promise<void> {
    const studentTutorsSubCollection = collection(
      db,
      this.collectionName,
      studentId,
      'tutors'
    );

    const batch = writeBatch(db);
    tutors.forEach((tutor) => {
      batch.set(doc(studentTutorsSubCollection, tutor.id), tutor);
    });
    await batch.commit();
  }
  async deleteTutorFromPrivateStudent(
    studentId: string,
    tutorId: string
  ): Promise<void> {
    const privateStudentTutorsCollection = collection(
      db,
      this.collectionName,
      studentId,
      'tutors'
    );
    const tutor = await getDoc(doc(privateStudentTutorsCollection, tutorId));
    await deleteDoc(tutor.ref);
  }
  async countOrganizationsStudents(): Promise<number> {
    const filterQuery = new FilterQueryBuilder(this.collection)
      .where(where('organizationId', '!=', null))
      .build();
    const numberOfOrganizationsStudents = this.countByQuery(filterQuery);
    return numberOfOrganizationsStudents;
  }

  async deleteAllStudentData(studentId: string): Promise<void> {
    await this.delete(studentId);
  }
}

export const studentRepository = new StudentRepository();
