import {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions
} from 'firebase/firestore';
import {
  ChatSupport,
  ChatSupportDoc
} from '../../entities/chat-support.entity';
import { FireDoc } from '../../types/util.types';

export const chatSupportConverter: FirestoreDataConverter<ChatSupportDoc> = {
  toFirestore(chat: ChatSupport): ChatSupportDoc {
    return {
      createdAt: chat.createdAt,
      status: chat.status,
      unreadCount: chat.unreadCount,
      latestMessageDate: chat.latestMessageDate
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot<ChatSupportDoc>,
    options: SnapshotOptions
  ): FireDoc<ChatSupportDoc>['DocWithIdAndRef'] {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      docRef: snapshot.ref,
      ...data
    };
  }
};
