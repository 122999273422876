import { FC, useEffect, useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { Student } from "../../../entities/student.entity";
import { useDeleteAllStudentDataMutation } from "../../../store/student.store";

interface DeleteStudentProps {
    onHide: () => void;
    student: Student;
    [key: string]: any;

}

const DeleteStudentModal: FC<DeleteStudentProps> = ({ onHide, student, ...props }) => {
    const [deleteStudent, deleteStudentResult] = useDeleteAllStudentDataMutation();
    const [disabled, setDisabled] = useState<boolean>();
    useEffect(() => {
        if (deleteStudentResult.isSuccess) 
            onHide()
    },[deleteStudentResult, onHide]);

    const handleAccept = () => {
        deleteStudent(student.id)
    }
    return (
        <Modal
            {...props}
            onHide={onHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title className='fs-6'>Do you want to delete the student?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>student name: {student.name}</p>
                <InputGroup className="mt-2 mb-3">
                    <Form.Control
                        placeholder="Enter Tutor Name"
                        onChange={(e) => setDisabled((e.target.value == student.name) || (student.name == null || student.name.trim() === ''))}
                    />
                </InputGroup>
            </Modal.Body>
            <Modal.Footer >
                <Button variant="secondary" onClick={onHide}>Close</Button>
                <Button 
                    variant="danger" 
                    onClick={handleAccept} 
                    disabled={!disabled || deleteStudentResult.isLoading}
                >delete</Button>
            </Modal.Footer>
        </Modal>
    );
};

const DeleteStudent = ({ student }: { student: Student}) => {
    const [modalShow, setModalShow] = useState(false);
    return (
        <>
            <Button size="sm" variant="danger" onClick={() => setModalShow(true)}>
                delete
            </Button>

            <DeleteStudentModal
                onHide={() => setModalShow(false)}
                show={modalShow}
                student={student}
            />
        </>
    );
}

export default DeleteStudent;