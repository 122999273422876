import { VoucherCodeStatus } from "../entities/voucherCode.entity";
import { bookingRepository } from "../repositories/booking.repository";
import { cancellationRequestRepository } from "../repositories/cancellationRequest.repository";
import { organizationRepository } from "../repositories/organization.repository";
import { sessionRepository } from "../repositories/session.repository";
import { studentRepository } from "../repositories/student.repository";
import { tutorRepository } from "../repositories/tutor.repository";
import { tutorWithdrawalRequestRepository } from "../repositories/tutorWithdrawalRequest.repository";
import { voucherCodeRepository } from "../repositories/voucherCode.repository";
import { GenericQueryReturnType } from "../store";
import { getLoggedInRoleKey } from "../utils/browserStorage.util";

export const findStatistics = async (): Promise<
	GenericQueryReturnType<"statistics", any>
> => {
	try {
		const role = getLoggedInRoleKey();

		if (role === "admin") {
			const [
				organizationsStudents,
				students,
				tutors,
				bookings,
				withdrawalRequests,
				redeemedVouchersCodes,
				cancellationRequests,
				organizations,
				sessions,
			] = await Promise.all([
				studentRepository.countOrganizationsStudents(),
				studentRepository.countExistingStudents(),
				tutorRepository.countExistingTutors(),
				bookingRepository.count(),
				tutorWithdrawalRequestRepository.count(),
				voucherCodeRepository.count({
					status: VoucherCodeStatus.AVAILABLE,
				}),
				cancellationRequestRepository.count(),
				organizationRepository.count(),
				sessionRepository.count(),
			]);


			return {
				data: {
					statistics: {
						organizations,
						"organization students": organizationsStudents,
						students,
						tutors,
						bookings,
						"withdrawal requests": withdrawalRequests,
						"redeemed vouchers codes": redeemedVouchersCodes,
						"cancellation requests": cancellationRequests,
						sessions,
					},
				},
			};

		}

		if (role === "employee") {
			const [
				students,
				tutors,
			] = await Promise.all([
				studentRepository.countExistingStudents(),
				tutorRepository.countExistingTutors(),
			]);

			return {
				data: {
					statistics: {
						students,
						tutors,
					},
				},
			};
		}

		return {
			data: { statistics: {} },
		};

	} catch (error) {
		console.log(error);
		return {
			error: true,
		};
	}
};
