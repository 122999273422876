import { FC, useEffect, useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { Tutor } from "../../../entities/tutor.entity";
import { useDeleteAllTutorDataMutation } from "../../../store/tutor.store";

interface ConvertTutorToStudentProps {
    onHide: () => void;
    tutor: Tutor;
    [key: string]: any;

}

const DeleteTutorModal: FC<ConvertTutorToStudentProps> = ({ onHide, tutor, ...props }) => {
    const [deleteTutor, deleteTutorResult] = useDeleteAllTutorDataMutation();
    const [disabled, setDisabled] = useState<boolean>();
    useEffect(() => {
        if (deleteTutorResult.isSuccess) 
            onHide()
    },[deleteTutorResult, onHide]);

    const handleAccept = () => {
        deleteTutor(tutor.id)
    }
    return (
        <Modal
            {...props}
            onHide={onHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title className='fs-6'>Do you want to delete tutor to student ?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>tutor name: {tutor.name}</p>
                <InputGroup className="mt-2 mb-3">
                    <Form.Control
                        placeholder="Enter Tutor Name"
                        onChange={(e) => setDisabled((e.target.value == tutor.name) || (tutor.name == null || tutor.name.trim() === ''))}
                    />
                </InputGroup>
            </Modal.Body>
            <Modal.Footer >
                <Button variant="secondary" onClick={onHide}>Close</Button>
                <Button 
                    variant="danger" 
                    onClick={handleAccept} 
                    disabled={!disabled || deleteTutorResult.isLoading}
                >delete</Button>
            </Modal.Footer>
        </Modal>
    );
};

const DeleteTutor = ({ tutor }: { tutor: Tutor}) => {
    const [modalShow, setModalShow] = useState(false);
    return (
        <>
            <Button size="sm" variant="danger" onClick={() => setModalShow(true)}>
                delete
            </Button>

            <DeleteTutorModal
                onHide={() => setModalShow(false)}
                show={modalShow}
                tutor={tutor}
            />
        </>
    );
}

export default DeleteTutor;